<template>

  <section class="content-section">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5">Puzzles</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row rats">
        <div class="col-12">
          <p>
            All 10k $RATs were given away, the faucet is now closed. Thanks for the overwhelming support, and I hope you all enjoy collecting.<br/>
            I have an announcement regarding <router-link to="/rat/id/1">Nexus [#1]</router-link>, <router-link to="/rat/id/2">Orion [#2]</router-link> and <router-link to="/rat/id/3">Jupiter [#3]</router-link>. They have fled to addresses I no longer control, but are findable to treasure hunters. Some rats may point in the right direction. Good luck!
          </p>
        </div>
        <div class="col-4 text-center">
          <a href="https://simpleledger.info/#address/simpleledger:qqwtdm558exp3cq93hz2ypdjhdexre2dlccp6dxw35" target="_blank">
            SOLVED by @bitarchitect
          </a>
          <br/>
          <router-link to="/rat/id/1">
            <img src='https://api.cryptor.at/original/rat/1' class="img-fluid rat-image" /><br/>
            <span style='height:60px;display:block;'>Nexus [#1]</span>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <a href="https://simpleledger.info/#address/simpleledger:qpzr7zphf6ql66ll9zfac94ygra04qzr4ugvp7xkcl" target="_blank">
            SOLVED by @c0xffee & @bitarchitect
          </a>
          <br/>
          <router-link to="/rat/id/2">
            <img src='https://api.cryptor.at/original/rat/2' class="img-fluid rat-image" /><br/>
            <span style='height:60px;display:block;'>Orion [#2]</span>
          </router-link>
        </div>
        <div class="col-4 text-center">
          <a href="https://simpleledger.info/#address/simpleledger:qpk2hpzmklclsur2ywquacw34fvj3uz9956jqrt7nn" target="_blank">
            SOLVED by @c0xffee
          </a>
          <br/>
          <router-link to="/rat/id/3">
            <img src='https://api.cryptor.at/original/rat/3' class="img-fluid rat-image" /><br/>
            <span style='height:60px;display:block;'>Jupiter [#3]</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {}
}
</script>